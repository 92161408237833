<script>
import Autocomplete from '@/components/general/Autocomplete'
export default {
  name: 'ModalInviteMember',
  components: { Autocomplete },
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      inviteList: [],
      workspaceSearch: '',
      workspaceSearchOptions: [],
      selectedRole: 'member',
      availableRoles: [
        'member',
        'administrator',
        'content_creator'
      ]
    }
  },
  watch: {
    workspaceSearch () {
      const filter = {
        page: 1,
        filter: {
          name: this.workspaceSearch
        },
        limit: 20
      }
      this.debounceEvent(() => {
        this.$store.dispatch('attemptGetPeopleSuggestions', { workspaceId: this.selectedWorkspace.lxID, filter })
          .then(({ data }) => {
            this.workspaceSearchOptions = data.data.map(item => {
              return {
                id: item.owner.userId,
                name: `${item.name} ${item.lastName}`,
                imageUrl: item.imageUrl,
                type: item.type,
                registeredEmail: item.email
              }
            })
          })
          .finally(() => {
            this.workspaceSearchOptions.push(...this.inviteList)
          })
      })
    }
  },
  computed: {
    canAddEmail () {
      return this.isEmail(this.workspaceSearch) && this.workspaceSearchOptions.every(person => person.registeredEmail !== this.workspaceSearch)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      const payload = {
        workspaceId: this.selectedWorkspace.lxID,
        invites: this.inviteList.map(invite => {
          return { userId: invite.id, role: this.selectedRole, email: invite.email }
        })
      }
      this.$store.dispatch('attemptInviteWorkspaceMember', payload)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('modal.invite.member:feedback.success')
          })
        })
        .finally(() => {
          this.close()
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.members.invites' })
        })
    },
    addEmail () {
      this.inviteList.push({ email: this.workspaceSearch, name: this.workspaceSearch })

      this.workspaceSearch = null
    }
  }
}
</script>
<template>
  <v-dialog :value="show" width="620" content-class="modal-invite-member--modal" @click:outside="close">
    <v-card class="modal-invite-member--container">
      <div class="modal-invite-member--header__wrapper">
        <h5 class="h7">{{ $t('modal.invite.member:title') }}</h5>
        <v-btn icon @click="close" color="#1200D3"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="modal-invite-member--body">
        <p class="modal-invite-member--description subtitle--1">{{ $t('modal.invite.member:description') }}</p>
        <autocomplete
          v-model="inviteList"
          :search-input.sync="workspaceSearch"
          :items="workspaceSearchOptions"
          item-text="name"
          :item-value="item => item"
          :placeholder="$t('modal.invite.member:invite.input')"
          :no-data-text="$t('modal.invite.member:invite.no.data')"
          outlined
          small
          hideValidation
          primary
          clearable
          multiple
          no-filter
          :hide-selected="canAddEmail || Boolean(workspaceSearch)"
        >
          <template v-slot:no-data><span/></template>
          <template v-slot:item="props">
            <div v-if="props.item.registeredEmail" class="modal-invite-member--custom-member">
              <v-img :src="props.item.imageUrl || defaultAvatarUrl(props.item.type === 'business' ? 'business' : 'profile')" height="36px" width="36px"></v-img>
              <span class="body--1">{{ props.item.name }}</span>
            </div>
            <div v-else class="modal-invite-member--custom-member">
              <v-icon class="mr-2 ml-1">mdi-email</v-icon>{{ props.item.name }}
            </div>
          </template>
          <template v-slot:selection="props">
            <div class="modal-invite-member--custom-member-chip">
              <v-img v-if="!props.item.email" :src="props.item.imageUrl || defaultAvatarUrl(props.item.type === 'business' ? 'business' : 'profile')" height="32px" width="32px"></v-img>
              <span class="body--1" :class="{ 'pl-4': !!props.item.email }">{{ props.item.name }}</span>
            </div>
          </template>
          <v-list-item v-if="canAddEmail" slot="append-item" @click="addEmail">
            <v-list-item-icon class="mr-2"><v-icon class="primary-medium">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content v-text="workspaceSearch"></v-list-item-content>
          </v-list-item>
        </autocomplete>
        <div class="modal-invite-member--roles__wrapper">
          <v-radio-group v-model="selectedRole">
            <v-radio
              class="modal-invite-member--custom-role-option"
              v-for="(role, index) in availableRoles"
              :key="index"
              :label="$t(`modal.invite.member:roles.${role}`)"
              :value="role"
              color="#1200D3"
            >
              <template v-slot:label>
                <div class="modal-invite-member--custom-role-label">
                  <h5 class="h8">{{ $t(`modal.invite.member:roles.${role}`) }}</h5>
                  <p class="body--1">{{ $t(`modal.invite.member:roles.${role}.description`) }}</p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <div class="modal-invite-member--actions">
          <v-btn
            text
            color="#1200D3"
            @click="close"
            class="text-secondary mr-2 bold btn transform-unset"
          >
            {{ $t('global:cancel') }}
          </v-btn>
          <v-btn
            class="bold"
            dark
            color="#1200D3"
            @click="submit"
          >
            {{ $t('global:confirm') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-invite-member--modal {
  overflow: visible;
}
.modal-invite-member--container {
  padding: 17px 20px;
  text-align: left;
  .modal-invite-member--header__wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-invite-member--body {
    .modal-invite-member--description {
      margin: 12px 0 24px;
      color: $neutral-medium;
    }
    .modal-invite-member--custom-member {
      display: flex;
      align-items: center;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
    .modal-invite-member--custom-member-chip {
      display: flex;
      align-items: center;
      height: 32px;
      color: $neutral-darkest;
      background-color: $neutral-light;
      border-radius: 100px;
      padding-right: 16px;
      margin-right: 4px;
      .v-image {
        border-radius: 100px;
        margin-right: 10px;
      }
    }
  }
  .modal-invite-member--roles__wrapper {
    margin-top: 24px;
    .modal-invite-member--custom-role-option.v-radio {
      margin-bottom: 24px;
    }
    .modal-invite-member--custom-role-label {
      p.body--1 {
        margin: 0;
      }
    }
  }
  .modal-invite-member--actions {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 768px) {
    .modal-invite-member--actions {
      .v-btn {
        width: calc(50% - 6px);
      }
    }
  }
}
</style>
